import Api from "../../../api/Api";

const getToken = () => {
  return localStorage.getItem("token-driver");
};

const getOrders =
  (status = "") =>
  (dispatch) => {
    Api.get(`/driver-orders?status=${status}`, {
      headers: { Authorization: getToken() },
    })
      .then((res) => {
        dispatch({
          type: "GET_ORDERS",
          payload: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

const getOrder = (id) => (dispatch) => {
  Api.get(`/driver-orders/${id}`, {
    headers: { Authorization: getToken() },
  })
    .then((res) => {
      dispatch({
        type: "GET_ORDER",
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log(err.response.data);
    });
};

const delivery = (id) => {
  return new Promise((resolve, reject) => {
    Api.put(
      `/driver-orders/${id}/delivery`,
      {},
      {
        headers: { Authorization: getToken() },
      }
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const upload = (id, form) => {
  return new Promise((resolve, reject) => {
    Api.put(`/driver-orders/${id}/proof-of-delivery`, form, {
      headers: { Authorization: getToken() },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export { getOrders, getOrder, delivery, upload };
