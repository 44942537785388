import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainApp from "./layouts/MainApp";
import Home from "./pages/Home";
import Login from "./pages/Login";
import My from "./pages/My";
import Order from "./pages/Order";
import OrderDetail from "./pages/Order/Detail";
import Register from "./pages/Register";
import store from "./redux/store";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<MainApp />}>
            <Route index element={<Home />} />
            <Route path="register" element={<Register />} />
            <Route path="login" element={<Login />} />
            <Route path="order">
              <Route index element={<Order />} />
              <Route path=":id" element={<OrderDetail />} />
            </Route>
            <Route path="my" element={<My />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
