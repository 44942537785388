import { combineReducers } from "redux";
import { orders, order } from "./order";
import { user } from "./user";
import { districts, provinces, regencies, villages } from "./wilayah";

const reducer = combineReducers({
  provinces,
  regencies,
  districts,
  villages,
  user,
  orders,
  order,
});

export default reducer;
