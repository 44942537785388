import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Gap from "../../../components/atoms/Gap";
import SectionItem from "../../../components/organisms/Order/Detail/SectionItem";
import SectionPayment from "../../../components/organisms/Order/Detail/SectionPayment";
import SectionShipping from "../../../components/organisms/Order/Detail/SectionShipping";
import SectionStatus from "../../../components/organisms/Order/Detail/SectionStatus";
import { authentication } from "../../../redux/action/auth";
import { getOrder } from "../../../redux/action/order";

const OrderDetail = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("token-driver")) {
      authentication()
        .then((res) => {
          dispatch({
            type: "GET_USER",
            payload: res.data,
          });
        })
        .catch((err) => {
          window.location.href = "/login";
        });
    } else {
      window.location.href = "/login";
    }
    dispatch(getOrder(id));
  }, []);

  const { order } = useSelector((state) => state.order);

  return (
    <>
      <div className="container">
        <SectionStatus order={order} />
        <SectionItem order={order} />
        <SectionShipping order={order} />
        <SectionPayment order={order} />
      </div>
      <Gap height={70} />
    </>
  );
};

export default OrderDetail;
