import Api from "../../../api/Api";

const getToken = () => {
  return localStorage.getItem("token-driver");
};

const storeRegister = (form) => {
  return new Promise((resolve, reject) => {
    Api.post("/driver-drivers", form)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const storeLogin = (form) => {
  return new Promise((resolve, reject) => {
    Api.post("/driver-drivers/login", form)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const authentication = () => {
  return new Promise((resolve, reject) => {
    Api.get("/driver-drivers", {
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export { authentication, storeLogin, storeRegister };
