import { useState } from "react";
import { useSelector } from "react-redux";
import { convertToBase64 } from "../../../redux/action/convertToBase64";
import { upload } from "../../../redux/action/order";
import Gap from "../../atoms/Gap";

const ModalProofOfDelivery = ({ orderId }) => {
  const { order } = useSelector((state) => state.order);

  const [previewImage, setPreviewImage] = useState();
  const [proofOfDelivery, setProofOfDelivery] = useState();
  const [validation, setValidation] = useState([]);

  const handlePreviewImage = (e) => {
    const [file] = e.target.files;

    if (!file) return;

    setPreviewImage(URL.createObjectURL(file));
  };

  const handleFileUpload = async (e) => {
    const [file] = e.target.files;

    if (!file) return;

    const { size, type } = file;

    if (type !== "image/jpg" && type !== "image/jpeg" && type !== "image/png") {
      alert("Tipe yang diperbolehkan hanya jpg, jpeg, & png");
      setProofOfDelivery();
    } else if (size > 1000000) {
      alert("Ukuran gambar maksimal 1 mb");
      setProofOfDelivery();
    } else {
      setProofOfDelivery(await convertToBase64(file));
    }
  };

  const formProofOfDelivery = (e) => {
    e.preventDefault();

    const form = {
      proofOfDelivery,
    };

    upload(orderId, form)
      .then((res) => {
        alert(res.message);
        window.location.reload();
      })
      .catch((err) => {
        if (err.message === "validation failed") {
          setValidation(err.data);
        } else {
          alert(err.message);
        }
      });
  };

  return (
    <div id="modal1" class="modal">
      <div class="modal-content">
        <form onSubmit={formProofOfDelivery}>
          <div style={styles.title}>Bukti Pengiriman</div>
          <Gap height={10} />
          <div className="center">
            {!previewImage ? (
              <img
                src={`//${order.proofOfDelivery}`}
                className="responsive-img"
                style={styles.oldImage}
              />
            ) : (
              <img
                src={previewImage}
                className="responsive-img"
                style={styles.image}
              />
            )}
          </div>
          <div className="file-field input-field">
            <div className="btn">
              <i className="material-icons">image</i>
              <input
                type="file"
                onChange={(e) => {
                  handlePreviewImage(e);
                  handleFileUpload(e);
                }}
              />
            </div>
            <div className="file-path-wrapper">
              <input className="file-path validate" type="text" />
            </div>
            {validation?.map((value, index) =>
              value.field === "proofOfDelivery" ? (
                <span className="helper-text red-text">{value.message}</span>
              ) : null
            )}
          </div>
          <div className="center">
            <button className="btn">Unggah Bukti Pengiriman</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalProofOfDelivery;

const styles = {
  title: { fontSize: 16 },
  oldImage: { width: 150, height: 150 },
  image: { width: 150 },
};
