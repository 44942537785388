import "leaflet/dist/leaflet.css";
import M from "materialize-css";
import { useEffect, useRef, useState } from "react";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Gap from "../../components/atoms/Gap";
import { authentication, storeRegister } from "../../redux/action/auth";
import { getWilayah } from "../../redux/action/wilayah";

const Register = () => {
  useEffect(() => {
    var elems = document.querySelectorAll("select");
    M.FormSelect.init(elems, {});
  });

  useEffect(() => {
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });

    if (localStorage.getItem("token-driver")) {
      authentication()
        .then((res) => {
          window.location.href = "/";
        })
        .catch((err) => {});
    }
  }, []);

  const mapRef = useRef(null);
  const defaultLatitude = -7.047592468597611;
  const defauktLongitude = 107.58868235131465;

  const LocationMarker = () => {
    const [position, setPosition] = useState(null);

    useMapEvents({
      click(e) {
        setLatitude(e.latlng.lat);
        setLongitude(e.latlng.lng);
        setPosition(e.latlng);
      },
    });

    return position === null ? null : <Marker position={position} />;
  };

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [gender, setGender] = useState();
  const [address, setAddress] = useState();
  const [province, setProvince] = useState("JAWA BARAT");
  const [regency, setRegency] = useState("KABUPATEN BANDUNG");
  const [district, setDistrict] = useState();
  const [village, setVillage] = useState();
  const [zipCode, setZipCode] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [pin, setPin] = useState();
  const [pinConfirmation, setPinConfirmation] = useState();
  const [validation, setValidation] = useState([]);

  const dispatch = useDispatch();

  const { districts } = useSelector((state) => state.districts);
  const { villages } = useSelector((state) => state.villages);

  const formRegister = (e) => {
    e.preventDefault();

    const form = {
      name,
      email,
      phone,
      gender,
      province,
      regency,
      district,
      village,
      zipCode,
      address,
      latitude,
      longitude,
      pin,
      pin_confirmation: pinConfirmation,
    };

    storeRegister(form)
      .then((res) => {
        alert(res.message);
        window.location.href = "/login";
      })
      .catch((err) => {
        if (err.message === "validation failed") {
          setValidation(err.data);
        } else {
          alert(err.message);
        }
      });
  };

  return (
    <>
      <div className="container">
        <form onSubmit={formRegister}>
          <div className="card" style={styles.card}>
            <div className="center" style={styles.title}>
              Registrasi
            </div>
            <div className="input-field">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label>Nama Lengkap</label>
              {validation?.map((value, index) =>
                value.field === "name" ? (
                  <span className="helper-text red-text">{value.message}</span>
                ) : null
              )}
            </div>
            <div className="input-field">
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label>Email (opsional)</label>
              {validation?.map((value, index) =>
                value.field === "email" ? (
                  <span className="helper-text red-text">{value.message}</span>
                ) : null
              )}
            </div>
            <div className="input-field">
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <label>No. Telp</label>
              {validation?.map((value, index) =>
                value.field === "email" ? (
                  <span className="helper-text red-text">{value.message}</span>
                ) : null
              )}
            </div>
            <div className="input-field">
              <select onChange={(e) => setGender(e.target.value)}>
                <option value="" selected disabled>
                  Pilih Jenis Kelamin
                </option>
                <option value="M">Laki - Laki</option>
                <option value="W">Perempuan</option>
              </select>
              <label>Jenis Kelamin</label>
              {validation?.map((value, index) =>
                value.field === "gender" ? (
                  <span className="helper-text red-text">{value.message}</span>
                ) : null
              )}
            </div>
            <div className="input-field">
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <label>Alamat</label>
              {validation?.map((value, index) =>
                value.field === "address" ? (
                  <span className="helper-text red-text">{value.message}</span>
                ) : null
              )}
            </div>
            <div className="input-field">
              <input
                type="text"
                value={province}
                onChange={(e) => setProvince(e.target.value)}
                disabled
              />
              <label className="active">Provinsi</label>
              {validation?.map((value, index) =>
                value.field === "province" ? (
                  <span className="helper-text red-text">{value.message}</span>
                ) : null
              )}
            </div>
            <div className="input-field">
              <select
                onChange={(e) => {
                  setRegency(JSON.parse(e.target.value).name);
                  dispatch(getWilayah("", JSON.parse(e.target.value).id, ""));
                }}
              >
                <option value="" selected disabled>
                  Pilih Kota / Kabupaten
                </option>
                <option
                  value={JSON.stringify({
                    id: "3204",
                    name: "KABUPATEN BANDUNG",
                  })}
                >
                  KABUPATEN BANDUNG
                </option>
                <option
                  value={JSON.stringify({ id: "3273", name: "KOTA BANDUNG" })}
                >
                  KOTA BANDUNG
                </option>
              </select>
              <label>Kota / Kabupaten</label>
              {validation?.map((value, index) =>
                value.field === "regency" ? (
                  <span className="helper-text red-text">{value.message}</span>
                ) : null
              )}
            </div>
            <div className="input-field">
              <select
                onChange={(e) => {
                  setDistrict(JSON.parse(e.target.value).name);
                  dispatch(getWilayah("", "", JSON.parse(e.target.value).id));
                }}
              >
                <option value="" selected disabled>
                  Pilih Kecamatan
                </option>
                {districts.map((value, index) => (
                  <option value={JSON.stringify(value)} key={index}>
                    {value.name}
                  </option>
                ))}
              </select>
              <label>Kecamatan</label>
              {validation?.map((value, index) =>
                value.field === "district" ? (
                  <span className="helper-text red-text">{value.message}</span>
                ) : null
              )}
            </div>
            <div className="input-field">
              <select
                onChange={(e) => {
                  setVillage(JSON.parse(e.target.value).name);
                }}
              >
                <option value="" selected disabled>
                  Pilih Kelurahan / Desa
                </option>
                {villages.map((value, index) => (
                  <option value={JSON.stringify(value)} key={index}>
                    {value.name}
                  </option>
                ))}
              </select>
              <label>Kelurahan / Desa</label>
              {validation?.map((value, index) =>
                value.field === "village" ? (
                  <span className="helper-text red-text">{value.message}</span>
                ) : null
              )}
            </div>
            <div className="input-field">
              <input
                type="text"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
              <label>Kode Pos (opsional)</label>
              {validation?.map((value, index) =>
                value.field === "zipCode" ? (
                  <span className="helper-text red-text">{value.message}</span>
                ) : null
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className="valign-wrapper">
                <i className="material-icons">location_on</i> Lokasi
              </span>
              {latitude && longitude ? (
                <span className="green-text" style={styles.statusLocation}>
                  <b>Sudah menandai lokasi!</b>
                </span>
              ) : (
                <span className="red-text" style={styles.statusLocation}>
                  <b>Belum menandai lokasi!</b>
                </span>
              )}
            </div>
            <Gap height={10} />
            <MapContainer
              center={[defaultLatitude, defauktLongitude]}
              zoom={13}
              ref={mapRef}
              style={styles.mapContainer}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <LocationMarker />
            </MapContainer>
            <div className="input-field">
              <input
                type="password"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
              />
              <label>Pin (6 Digit Angka)</label>
              {validation?.map((value, index) =>
                value.field === "pin" ? (
                  <span className="helper-text red-text">{value.message}</span>
                ) : null
              )}
            </div>
            <div className="input-field">
              <input
                type="password"
                value={pinConfirmation}
                onChange={(e) => setPinConfirmation(e.target.value)}
              />
              <label>Pin Konfirmasi (6 Digit Angka)</label>
              {validation?.map((value, index) =>
                value.field === "pinConfirmation" ? (
                  <span className="helper-text red-text">{value.message}</span>
                ) : null
              )}
            </div>
            <div className="input-field center">
              <button className="btn-large">Daftar</button>
              <Gap height={5} />
              <div>
                <Link to="/login" style={styles.link}>
                  Sudah punya akun? Masuk disini!
                </Link>
              </div>
            </div>
            <Gap height={10} />
          </div>
        </form>
      </div>
      <Gap height={70} />
    </>
  );
};

export default Register;

const styles = {
  card: { padding: 10, borderRadius: 10 },
  title: { fontSize: 22 },
  statusLocation: { fontSize: 17 },
  mapContainer: { height: "35vh", width: "82vw" },
  link: { fontSize: 16 },
};
