import { useEffect, useState } from "react";
import Gap from "../../components/atoms/Gap";
import { authentication, storeLogin } from "../../redux/action/auth";

const Login = () => {
  useEffect(() => {
    if (localStorage.getItem("token-driver")) {
      authentication()
        .then((res) => {
          window.location.href = "/";
        })
        .catch((err) => {});
    }
  }, []);

  const [phone, setPhone] = useState();
  const [pin, setPin] = useState();
  const [validation, setValidation] = useState([]);

  const formLogin = (e) => {
    e.preventDefault();

    const form = {
      phone,
      pin,
    };

    storeLogin(form)
      .then((res) => {
        localStorage.setItem("token-driver", res.data);
        alert(res.message);
        window.location.href = "/";
      })
      .catch((err) => {
        if (err.message === "validation failed") {
          setValidation(err.data);
        } else {
          alert(err.message);
        }
      });
  };

  return (
    <>
      <div className="container">
        <form onSubmit={formLogin}>
          <div className="card" style={styles.card}>
            <div className="center" style={styles.title}>
              Sign In
            </div>
            <div className="input-field">
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <label>No. Telp</label>
              {validation?.map((value, index) =>
                value.field === "phone" ? (
                  <span className="helper-text red-text">{value.message}</span>
                ) : null
              )}
            </div>
            <div className="input-field">
              <input
                type="password"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
              />
              <label>Pin</label>
              {validation?.map((value, index) =>
                value.field === "pin" ? (
                  <span className="helper-text red-text">{value.message}</span>
                ) : null
              )}
            </div>
            <div className="input-field center">
              <button className="btn-large">Masuk</button>
              <Gap height={5} />
              <div>
                <a href="/register" style={styles.link}>
                  Belum punya akun? Daftar disini!
                </a>
              </div>
            </div>
            <Gap height={10} />
          </div>
        </form>
      </div>
      <Gap height={70} />
    </>
  );
};

export default Login;

const styles = {
  card: { padding: 10, borderRadius: 10 },
  title: { fontSize: 22 },
  link: { fontSize: 16 },
};
