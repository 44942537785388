import M from "materialize-css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Gap from "../../components/atoms/Gap";
import ModalProofOfDelivery from "../../components/organisms/Order/ModalProofOfDelivery";
import SectionFilter from "../../components/organisms/Order/SectionFilter";
import SectionOrder from "../../components/organisms/Order/SectionOrder";
import { authentication } from "../../redux/action/auth";
import { getOrders } from "../../redux/action/order";

const Order = () => {
  useEffect(() => {
    var elemsSelect = document.querySelectorAll("select");
    M.FormSelect.init(elemsSelect, {});
  });

  useEffect(() => {
    var elemsModal = document.querySelectorAll(".modal");
    M.Modal.init(elemsModal, {});
  }, []);

  let [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("token-driver")) {
      authentication()
        .then((res) => {
          dispatch({
            type: "GET_USER",
            payload: res.data,
          });
        })
        .catch((err) => {
          window.location.href = "/login";
        });
    } else {
      window.location.href = "/login";
    }
    if (!status) {
      dispatch(getOrders());
    } else {
      dispatch(getOrders(status));
    }
  }, []);

  const [orderId, setOrderId] = useState();

  return (
    <>
      <div className="container">
        <SectionFilter status={status} />
        <SectionOrder setOrderId={setOrderId} />
      </div>
      <Gap height={70} />
      <ModalProofOfDelivery orderId={orderId} />
    </>
  );
};

export default Order;
