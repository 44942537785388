import Api from "../../../api/Api";

const getWilayah = (provinceId, regencyId, districtId) => (dispatch) => {
  if (provinceId) {
    Api.get(`/wilayah?provinceId=${provinceId}`)
      .then((res) => {
        dispatch({
          type: "GET_REGENCIES",
          payload: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  if (regencyId) {
    Api.get(`/wilayah?regencyId=${regencyId}`)
      .then((res) => {
        dispatch({
          type: "GET_DISTRICTS",
          payload: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  if (districtId) {
    Api.get(`/wilayah?districtId=${districtId}`)
      .then((res) => {
        dispatch({
          type: "GET_VILLAGES",
          payload: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  Api.get(`/wilayah`)
    .then((res) => {
      dispatch({
        type: "GET_PROVINCES",
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log(err.response.data);
    });
};

export { getWilayah };
