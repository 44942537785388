import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { authentication } from "../../redux/action/auth";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("token-driver")) {
      authentication()
        .then((res) => {
          dispatch({
            type: "GET_USER",
            payload: res.data,
          });
        })
        .catch((err) => {
          window.location.href = "/login";
        });
    } else {
      window.location.href = "/login";
    }
  }, []);

  return (
    <div className="container">
      <div className="row">
        <Link to="/order" className="col s6 m4 grey-text text-darken-3">
          <div className="card" style={styles.card}>
            <div className="card-content center">
              <i className="material-icons">event_note</i>
              <div>Pesanan</div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Home;

const styles = {
  card: { padding: 10, borderRadius: 10, fontSize: 18 },
};
