const SectionFilter = ({ status }) => {
  return (
    <div className="card" style={styles.card}>
      <select
        onChange={(e) =>
          (window.location.href = `order?status=${e.target.value}`)
        }
      >
        <option value="" disabled selected>
          Pilih Status
        </option>
        <option value="" selected={status === ""}>
          Semua
        </option>
        <option value="pending" selected={status === "pending"}>
          Belum Bayar
        </option>
        <option value="success" selected={status === "success"}>
          Disetujui
        </option>
        <option value="packing" selected={status === "packing"}>
          Diproses
        </option>
        <option value="delivery" selected={status === "delivery"}>
          Sedang Diantar
        </option>
        <option value="delivered" selected={status === "delivered"}>
          Telah Diantar
        </option>
        <option value="failure" selected={status === "failure"}>
          Gagal
        </option>
      </select>
    </div>
  );
};

export default SectionFilter;

const styles = {
  card: { padding: 10, borderRadius: 10 },
};
